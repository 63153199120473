.body{
	display: flex;
	flex-direction: column;
  	align-items: center;
  	justify-content: center;
}

.innerBody {
	width: 70%;
}

.innerBody h1 {
	text-align:  center;
}
.firstChild {
	align-self: stretch;
}

.graph {
    text-align: -webkit-center;
}

.tableData {
	text-align: center;
}

.form {
	display: flex;
	flex-flow: column wrap;
	justify-content: center;
	align-items: center;
	align-content: center;
}

.input-feedback {
	margin-top: 0px
}

.redRow {
	background-color: #f7a8a8;
}

.greenRow {
	background-color: #b3f7a8;
}

.conversions {
	display: flex;
	flex-flow: row wrap;
	align-items: center;
	justify-content: space-evenly;
	align-content: space-around;
	text-align: center;
	width: 70%;
}

.conversions h2 {
	width: 33%;
}

.ant-form {
	width: 100%;
}

.currencyForm ant-form-item-control {
	margin-right: 30px;
}