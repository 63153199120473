.payContainer {
	max-width: 150px
}

.quickPayContainer {
	display: inline-block;
}

.payBody {
	display: flex;
    flex-wrap: nowrap;
    align-content: space-between;
    justify-content: space-between;
    align-items: center;
}

td .payContainer {
	margin: auto;
}

.period {
	max-width: 50px;
	text-align: center;
}

.name {
	max-width: 100px;
	text-align: center;
}

#adminCenter {
	text-align: center;
	margin-bottom: 50px
}

.customPayButton {
	border-radius: 20px;
}