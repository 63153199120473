body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 14px;
  line-height: 1.5;
  color: #24292e;
  background-color: #fff;
}

.app {
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

input.error {
  border-color: red;
}


.input-feedback {
  color: red;
  height: 5px;
  margin-top: -12px;
}

@keyframes flash {
  0% { opacity: 1; }
  50% { opacity: 0; }
  100% { opacity: 1; }
}

a.flash {
  animation: flash .5s 4;
}

.picker {
  margin-right: auto;
}

.classInnerBody {
  margin: auto;
  width: 70%;
}

.classBody {
  display: flex;
  flex-direction: column;
}

.centerText {
  text-align: center;
  vertical-align: middle;
}

.red {
  background: red;
}

.red:active {
  background:white;
}

.green {
  background: green;
}

.green:active {
  background:white;
}

.blue {
  background: #4096ff;
}

.pickerStyle {
  margin:  0 auto;
  padding-left: 7px;
  padding-right: 7px;
  min-width: 205px;
  min-height: 30px;
  font-size: 25px;
  user-select: none;
}

.pickerStyle:active {
  background:white;
}

.privacy-policy {
  font-family: Arial, sans-serif;
  margin: 20px;
}

.privacy-title {
  font-size: 24px;
  margin-bottom: 10px;
}

.privacy-date {
  font-size: 14px;
  color: #888;
  margin-bottom: 20px;
}

.privacy-policy section {
  margin-bottom: 30px;
}

.privacy-policy h2 {
  font-size: 18px;
  margin-bottom: 10px;
}

.privacy-policy p {
  line-height: 1.6;
  margin-bottom: 15px;
}

.privacy-policy a {
  color: #007bff;
  text-decoration: none;
}

.privacy-policy a:hover {
  text-decoration: underline;
}

.privacy-consent {
  font-style: italic;
  color: #888;
}

.privacy-policy-link {
  position: fixed;
  bottom: 10px;
  left: 10px;
  font-size: 0.8rem;
  color: #aaa;
  z-index: 999;
}

.updates-link {
  position: fixed;
  bottom: 10px;
  right: 10px;
  font-size: 0.8rem;
  color: #aaa;
  z-index: 999;
}
