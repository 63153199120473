.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input { 
  opacity: 0;
  width: 0;
  height: 0;
}

.flexContainer {
  display: flex;
  flex-direction: row;
  align-content: space-between;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
  gap: 45px;
}

.flexContainerColumn {
  display: flex;
  flex-direction: column;
  align-content: space-between;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
  gap: 45px;
}

.flexContainerWrap {
  display: flex;
  flex-direction: row;
  align-content: flex-start;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 45px;
}

.flexContainerNoWrap {
  width: max-content;
  display: flex;
  flex-direction: row;
  align-content: flex-start;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 45px;
}

.leftContainer {
 float: left;
 margin-left: 1rem;
 display: inherit;
}

.rightContainer {
 float: right;
 margin-right: 1rem;
 display: inherit;
}

.spread {
  flex: auto;
  align-self: auto;
  padding: 1%;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #2196F3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.infinite-container {
  height: 300px;
  padding: 8px 24px;
  overflow: auto;
  border: 1px solid #e8e8e8;
  border-radius: 4px;
}
.loading-container {
  position: absolute;
  bottom: 40px;
  width: 100%;
  text-align: center;
}
  [data-theme="dark"] .demo-infinite-container {
    border: 1px solid #303030;
  }